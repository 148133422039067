<template>
  <div class="ca-page">
    <v-row class="ca-page-title">
      <v-col>
        <h1 class="ca-title">Design Style</h1>
        <h2 class="ca-subtitle">
          Which of these examples best represents your style?
        </h2>
      </v-col>
    </v-row>
    <v-row class="ca-content">
      <v-col cols="12" class="ca-banners ca-options">
        <v-row v-for="(chunk, i) in chunkBanners" :key="i">
          <v-col v-for="(col, i) in chunk" md="6" sm="12" :key="i">
            <banner :option="col.option" :pictures="col.pictures" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import Banner from "../components/Banner.vue";

export default {
  data() {
    return {
      banners: [
        {
          option: "established",
          pictures: [
            {
              brand: "Acura",
              src: require("../assets/images/banners/Acura/CM-BS-25-Acura-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/838/original/CM-BS-25-Acura-Established.jpg?1738335065"
            },
            {
              brand: "Audi",
              src: require("../assets/images/banners/Audi/CM-MC-25-Audi-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/842/original/CM-MC-25-Audi-Established.jpg?1738336823"
            },
            {
              brand: "BMW",
              src: require("../assets/images/banners/BMW/CM-BS-25-BMW-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/846/original/CM-BS-25-BMW-Established.jpg?1738338859"
            },
            {
              brand: "Buick",
              src: require("../assets/images/banners/Buick/CM-MC-25-Buick-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/850/original/CM-MC-25-Buick-Established.jpg?1738338939"
            },
            {
              brand: "Cadillac",
              src: require("../assets/images/banners/Cadillac/CM-KT-25-Cadillac-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/854/original/CM-KT-25-Cadillac-Established.jpg?1738339031"
            },
            {
              brand: "Chevrolet",
              src: require("../assets/images/banners/Chevrolet/CM-KT-25-Chevy-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/858/original/CM-KT-25-Chevy-Established.jpg?1738339110"
            },
            {
              brand: "CJDR",
              src: require("../assets/images/banners/CJDR/CM-BS-24-CJDR-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/107/original/CM-BS-24-CJDR-Established.jpg?1714510193"
            },
            {
              brand: "Ford",
              src: require("../assets/images/banners/Ford/CM-SS-25-Ford-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/862/original/CM-SS-25-Ford-Established.jpg?1738339193"
            },
            {
              brand: "GMC",
              src: require("../assets/images/banners/GMC/CM-JRF-25-GMC-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/866/original/CM-JRF-25-GMC-Established.jpg?1738339290"
            },
            {
              brand: "Honda",
              src: require("../assets/images/banners/Honda/CM-AE-25-Honda-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/870/original/CM-AE-25-Honda-Established.jpg?1738339375"
            },
            {
              brand: "Hyundai",
              src: require("../assets/images/banners/Hyundai/CM-MC-25-Hyundai-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/874/original/CM-MC-25-Hyundai-Established.jpg?1738339450"
            },
            {
              brand: "INFINITI",
              src: require("../assets/images/banners/Infiniti/CM-AB-25-INFINITI-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/878/original/CM-AB-25-INFINITI-Established.jpg?1738339558"
            },
            {
              brand: "Kia",
              src: require("../assets/images/banners/Kia/CM-PW-25-Kia-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/882/original/CM-PW-25-Kia-Established.jpg?1738339676"
            },
            {
              brand: "Lincoln",
              src: require("../assets/images/banners/Lincoln/CM-EC-25-Lincoln-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/888/original/CM-EC-25-Lincoln-Established.jpg?1738339757"
            },
            {
              brand: "Mazda",
              src: require("../assets/images/banners/Mazda/CM-SS-25-Mazda-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/892/original/CM-SS-25-Mazda-Established.jpg?1738339829"
            },
            {
              brand: "Mitsubishi",
              src: require("../assets/images/banners/Mitsubishi/CM-SS-25-Mitsubishi-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/896/original/CM-SS-25-Mitsubishi-Established.jpg?1738339894"
            },
            {
              brand: "Nissan",
              src: require("../assets/images/banners/Nissan/CM-DJ-25-Nissan-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/900/original/CM-DJ-25-Nissan-Established.jpg?1738339955"
            },
            {
              brand: "Subaru",
              src: require("../assets/images/banners/Subaru/CM-AE-25-Subaru-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/904/original/CM-AE-25-Subaru-Established.jpg?1738340020"
            },
            {
              brand: "Toyota",
              src: require("../assets/images/banners/Toyota/CM-MC-25-Toyota-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/908/original/CM-MC-25-Toyota-Established.jpg?1738340082"
            },
            {
              brand: "Volkswagen",
              src: require("../assets/images/banners/Volkswagen/CM-SS-25-Volkswagen-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/912/original/CM-SS-25-Volkswagen-Established.jpg?1738340147"
            },
            {
              brand: "Volvo",
              src: require("../assets/images/banners/Volvo/CM-MC-25-Volvo-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/916/original/CM-MC-25-Volvo-Established.jpg?1738340212"
            },
          ],
        },
        {
          option: "lifestyle",
          pictures: [
            {
              brand: "Acura",
              src: require("../assets/images/banners/Acura/CM-BS-25-Acura-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/839/original/CM-BS-25-Acura-Lifestyle.jpg?1738335066"
            },
            {
              brand: "Audi",
              src: require("../assets/images/banners/Audi/CM-MC-25-Audi-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/843/original/CM-MC-25-Audi-Lifestyle.jpg?1738336824"
            },
            {
              brand: "BMW",
              src: require("../assets/images/banners/BMW/CM-BS-25-BMW-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/847/original/CM-BS-25-BMW-Lifestyle.jpg?1738338860"
            },
            {
              brand: "Buick",
              src: require("../assets/images/banners/Buick/CM-MC-25-Buick-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/851/original/CM-MC-25-Buick-Lifestyle.jpg?1738338940"
            },
            {
              brand: "Cadillac",
              src: require("../assets/images/banners/Cadillac/CM-KT-25-Cadillac-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/855/original/CM-KT-25-Cadillac-Lifestyle.jpg?1738339032"
            },
            {
              brand: "Chevrolet",
              src: require("../assets/images/banners/Chevrolet/CM-KT-25-Chevy-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/859/original/CM-KT-25-Chevy-Lifestyle.jpg?1738339110"
            },
            {
              brand: "CJDR",
              src: require("../assets/images/banners/CJDR/CM-BS-24-CJDR-lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/106/original/CM-BS-24-CJDR-lifestyle.jpg?1714510193"
            },
            {
              brand: "Ford",
              src: require("../assets/images/banners/Ford/CM-SS-25-Ford-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/863/original/CM-SS-25-Ford-Lifestyle.jpg?1738339194"
            },
            {
              brand: "GMC",
              src: require("../assets/images/banners/GMC/CM-JRF-25-GMC-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/867/original/CM-JRF-25-GMC-Lifestyle.jpg?1738339291"
            },
            {
              brand: "Honda",
              src: require("../assets/images/banners/Honda/CM-AE-25-Honda-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/871/original/CM-AE-25-Honda-Lifestyle.jpg?1738339377"
            },
            {
              brand: "Hyundai",
              src: require("../assets/images/banners/Hyundai/CM-MC-25-Hyundai-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/875/original/CM-MC-25-Hyundai-Lifestyle.jpg?1738339450"
            },
            {
              brand: "INFINITI",
              src: require("../assets/images/banners/Infiniti/CM-AB-25-INFINITI-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/879/original/CM-AB-25-INFINITI-Lifestyle.jpg?1738339559"
            },
            {
              brand: "Kia",
              src: require("../assets/images/banners/Kia/CM-PW-25-Kia-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/883/original/CM-PW-25-Kia-Lifestyle.jpg?1738339677"
            },
            {
              brand: "Lincoln",
              src: require("../assets/images/banners/Lincoln/CM-EC-25-Lincoln-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/889/original/CM-EC-25-Lincoln-Lifestyle.jpg?1738339758"
            },
            {
              brand: "Mazda",
              src: require("../assets/images/banners/Mazda/CM-SS-25-Mazda-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/893/original/CM-SS-25-Mazda-Lifestyle.jpg?1738339829"
            },
            {
              brand: "Mitsubishi",
              src: require("../assets/images/banners/Mitsubishi/CM-SS-25-Mitsubishi-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/897/original/CM-SS-25-Mitsubishi-Lifestyle.jpg?1738339894"
            },
            {
              brand: "Nissan",
              src: require("../assets/images/banners/Nissan/CM-DJ-25-Nissan-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/901/original/CM-DJ-25-Nissan-Lifestyle.jpg?1738339956"
            },
            {
              brand: "Subaru",
              src: require("../assets/images/banners/Subaru/CM-AE-25-Subaru-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/905/original/CM-AE-25-Subaru-Lifestyle.jpg?1738340021"
            },
            {
              brand: "Toyota",
              src: require("../assets/images/banners/Toyota/CM-MC-25-Toyota-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/909/original/CM-MC-25-Toyota-Lifestyle.jpg?1738340083"
            },
            {
              brand: "Volkswagen",
              src: require("../assets/images/banners/Volkswagen/CM-SS-25-Volkswagen-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/913/original/CM-SS-25-Volkswagen-Lifestyle.jpg?1738340147"
            },
            {
              brand: "Volvo",
              src: require("../assets/images/banners/Volvo/CM-MC-25-Volvo-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/917/original/CM-MC-25-Volvo-Lifestyle.jpg?1738340213"
            },
          ],
        },
        {
          option: "modern",
          pictures: [
            {
              brand: "Acura",
              src: require("../assets/images/banners/Acura/CM-BS-25-Acura-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/840/original/CM-BS-25-Acura-Modern.jpg?1738335066"
            },
            {
              brand: "Audi",
              src: require("../assets/images/banners/Audi/CM-MC-25-Audi-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/844/original/CM-MC-25-Audi-Modern.jpg?1738336824"
            },
            {
              brand: "BMW",
              src: require("../assets/images/banners/BMW/CM-BS-25-BMW-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/848/original/CM-BS-25-BMW-Modern.jpg?1738338860"
            },
            {
              brand: "Buick",
              src: require("../assets/images/banners/Buick/CM-MC-25-Buick-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/852/original/CM-MC-25-Buick-Modern.jpg?1738338941"
            },
            {
              brand: "Cadillac",
              src: require("../assets/images/banners/Cadillac/CM-KT-25-Cadillac-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/856/original/CM-KT-25-Cadillac-Modern.jpg?1738339032"
            },
            {
              brand: "Chevrolet",
              src: require("../assets/images/banners/Chevrolet/CM-KT-25-Chevy-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/860/original/CM-KT-25-Chevy-Modern.jpg?1738339111"
            },
            {
              brand: "CJDR",
              src: require("../assets/images/banners/CJDR/CM-BS-24-CJDR-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/108/original/CM-BS-24-CJDR-Modern.jpg?1714510194"
            },
            {
              brand: "Ford",
              src: require("../assets/images/banners/Ford/CM-SS-25-Ford-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/864/original/CM-SS-25-Ford-Modern.jpg?1738339195"
            },
            {
              brand: "GMC",
              src: require("../assets/images/banners/GMC/CM-JRF-25-GMC-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/868/original/CM-JRF-25-GMC-Modern.jpg?1738339292"
            },
            {
              brand: "Honda",
              src: require("../assets/images/banners/Honda/CM-AE-25-Honda-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/872/original/CM-AE-25-Honda-Modern.jpg?1738339377"
            },
            {
              brand: "Hyundai",
              src: require("../assets/images/banners/Hyundai/CM-MC-25-Hyundai-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/876/original/CM-MC-25-Hyundai-Modern.jpg?1738339451"
            },
            {
              brand: "INFINITI",
              src: require("../assets/images/banners/Infiniti/CM-AB-25-INFINITI-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/880/original/CM-AB-25-INFINITI-Modern.jpg?1738339559"
            },
            {
              brand: "Kia",
              src: require("../assets/images/banners/Kia/CM-PW-25-Kia-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/884/original/CM-PW-25-Kia-Modern.jpg?1738339678"
            },
            {
              brand: "Lincoln",
              src: require("../assets/images/banners/Lincoln/CM-EC-25-Lincoln-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/890/original/CM-EC-25-Lincoln-Modern.jpg?1738339758"
            },
            {
              brand: "Mazda",
              src: require("../assets/images/banners/Mazda/CM-SS-25-Mazda-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/894/original/CM-SS-25-Mazda-Modern.jpg?1738339830"
            },
            {
              brand: "Mitsubishi",
              src: require("../assets/images/banners/Mitsubishi/CM-SS-25-Mitsubishi-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/898/original/CM-SS-25-Mitsubishi-Modern.jpg?1738339895"
            },
            {
              brand: "Nissan",
              src: require("../assets/images/banners/Nissan/CM-DJ-25-Nissan-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/902/original/CM-DJ-25-Nissan-Modern.jpg?1738339957"
            },
            {
              brand: "Subaru",
              src: require("../assets/images/banners/Subaru/CM-AE-25-Subaru-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/906/original/CM-AE-25-Subaru-Modern.jpg?1738340022"
            },
            {
              brand: "Toyota",
              src: require("../assets/images/banners/Toyota/CM-MC-25-Toyota-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/910/original/CM-MC-25-Toyota-Modern.jpg?1738340083"
            },
            {
              brand: "Volkswagen",
              src: require("../assets/images/banners/Volkswagen/CM-SS-25-Volkswagen-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/914/original/CM-SS-25-Volkswagen-Modern.jpg?1738340148"
            },
            {
              brand: "Volvo",
              src: require("../assets/images/banners/Volvo/CM-MC-25-Volvo-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/918/original/CM-MC-25-Volvo-Modern.jpg?1738340213"
            },
          ],
        },
        {
          option: "playful",
          pictures: [
            {
              brand: "Acura",
              src: require("../assets/images/banners/Acura/CM-BS-25-Acura-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/841/original/CM-BS-25-Acura-Playful.jpg?1738335067"
            },
            {
              brand: "Audi",
              src: require("../assets/images/banners/Audi/CM-MC-25-Audi-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/845/original/CM-MC-25-Audi-Playful.jpg?1738336825"
            },
            {
              brand: "BMW",
              src: require("../assets/images/banners/BMW/CM-BS-25-BMW-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/849/original/CM-BS-25-BMW-Playful.jpg?1738338861"
            },
            {
              brand: "Buick",
              src: require("../assets/images/banners/Buick/CM-MC-25-Buick-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/853/original/CM-MC-25-Buick-Playful.jpg?1738338941"
            },
            {
              brand: "Cadillac",
              src: require("../assets/images/banners/Cadillac/CM-KT-25-Cadillac-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/857/original/CM-KT-25-Cadillac-Playful.jpg?1738339032"
            },
            {
              brand: "Chevrolet",
              src: require("../assets/images/banners/Chevrolet/CM-KT-25-Chevy-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/861/original/CM-KT-25-Chevy-Playful.jpg?1738339111"
            },
            {
              brand: "CJDR",
              src: require("../assets/images/banners/CJDR/CM-BS-24-CJDR-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/109/original/CM-BS-24-CJDR-Playful.jpg?1714510194"
            },
            {
              brand: "Ford",
              src: require("../assets/images/banners/Ford/CM-SS-25-Ford-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/865/original/CM-SS-25-Ford-Playful.jpg?1738339195"
            },
            {
              brand: "GMC",
              src: require("../assets/images/banners/GMC/CM-JRF-25-GMC-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/869/original/CM-JRF-25-GMC-Playful.jpg?1738339293"
            },
            {
              brand: "Honda",
              src: require("../assets/images/banners/Honda/CM-AE-25-Honda-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/873/original/CM-AE-25-Honda-Playful.jpg?1738339378"
            },
            {
              brand: "Hyundai",
              src: require("../assets/images/banners/Hyundai/CM-MC-25-Hyundai-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/877/original/CM-MC-25-Hyundai-Playful.jpg?1738339451"
            },
            {
              brand: "INFINITI",
              src: require("../assets/images/banners/Infiniti/CM-AB-25-INFINITI-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/881/original/CM-AB-25-INFINITI-Playful.jpg?1738339560"
            },
            {
              brand: "Kia",
              src: require("../assets/images/banners/Kia/CM-PW-25-Kia-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/885/original/CM-PW-25-Kia-Playful.jpg?1738339679"
            },
            {
              brand: "Lincoln",
              src: require("../assets/images/banners/Lincoln/CM-EC-25-Lincoln-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/891/original/CM-EC-25-Lincoln-Playful.jpg?1738339759"
            },
            {
              brand: "Mazda",
              src: require("../assets/images/banners/Mazda/CM-SS-25-Mazda-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/895/original/CM-SS-25-Mazda-Playful.jpg?1738339831"
            },
            {
              brand: "Mitsubishi",
              src: require("../assets/images/banners/Mitsubishi/CM-SS-25-Mitsubishi-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/899/original/CM-SS-25-Mitsubishi-Playful.jpg?1738339895"
            },
            {
              brand: "Nissan",
              src: require("../assets/images/banners/Nissan/CM-DJ-25-Nissan-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/903/original/CM-DJ-25-Nissan-Playful.jpg?1738339957"
            },
            {
              brand: "Subaru",
              src: require("../assets/images/banners/Subaru/CM-AE-25-Subaru-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/907/original/CM-AE-25-Subaru-Playful.jpg?1738340023"
            },
            {
              brand: "Toyota",
              src: require("../assets/images/banners/Toyota/CM-MC-25-Toyota-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/911/original/CM-MC-25-Toyota-Playful.jpg?1738340083"
            },
            {
              brand: "Volkswagen",
              src: require("../assets/images/banners/Volkswagen/CM-SS-25-Volkswagen-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/915/original/CM-SS-25-Volkswagen-Playful.jpg?1738340149"
            },
            {
              brand: "Volvo",
              src: require("../assets/images/banners/Volvo/CM-MC-25-Volvo-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/001/286/919/original/CM-MC-25-Volvo-Playful.jpg?1738340214"
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.sortPictures();
    window.scrollTo(0, 0);
  },
  methods: {
    sortPictures() {
      const brand = this.$store.state.brand;
      const brandExists = this.banners[0].pictures.findIndex(
        (el) => el.brand === brand
      );
      if (brandExists === -1) return;
      this.banners.forEach((category, i) => {
        const brandPicture = category.pictures.find(
          (picture) => picture.brand === brand
        );
        let filtered = category.pictures.filter((picture) => {
          return picture.brand !== brand;
        });
        filtered.unshift(brandPicture);
        this.banners[i].pictures = filtered;
      });
    },
  },
  computed: {
    chunkBanners() {
      return _.chunk(this.banners, 2);
    },
  },
  components: {
    Banner,
  },
};
</script>
